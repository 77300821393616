import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GaAuthModule } from '@ga/central-auth-angular';
import { MaterialUIModule } from '../material-ui/material-ui.module';
import { SessionTimeoutDialogComponent } from './session-timeout-dialog.component';

@NgModule({
  declarations: [SessionTimeoutDialogComponent],
  imports: [CommonModule, MaterialUIModule, GaAuthModule],
  exports: [SessionTimeoutDialogComponent],
})
export class SessionTimeoutDialogModule {}
